import React from 'react';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Title } from '../../../components/texts';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { InputText } from '../../../components/inputs/InputText';
import { Button } from '../../../components/buttons';
import RNPickerSelect from 'react-native-picker-select';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useCaregiversContext } from '../../../providers/CaregiversProvider';
import { getDifferences } from '../../../utils/helpers/getDifferencesBetweenArrays';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { ROUTES } from '../../../router/Routes';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../providers/AuthProvider';
import { DefaultService } from '../../../generated/api';
import { useUserContext } from '../../../providers/UserProvider';

const UpdateCaregiverScreen = () => {
  const caregiverContext = useCaregiversContext();
  const authContext = useAuthContext();
  const userContext = useUserContext();
  const { displaySnackBar } = useSnackBarContext();
  const { navigate } = useNavigation();

  const [id, setId] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [name, setName] = React.useState('');
  const [firstname, setFirstName] = React.useState('');
  const [departmentsOfHospital, setDepartmentsOfHospital] = React.useState<Array<any>>([]);
  const [departments, setDepartments] = React.useState<Array<any>>([]);
  const [currentDepartment, setCurrentDepartment] = React.useState();
  const [job, setJob] = React.useState('');
  const [jobs, setJobs] = React.useState([
    { label: 'IDE', value: '1' },
    { label: 'AF', value: '2' },
    { label: 'SF', value: '3' },
    { label: 'CADRE', value: '5' },
  ]);
  const caregiver: any = useRoute();
  const caregiverData = useQuery(
    ['caregiverData', authContext.authState.token, caregiver.params],
    async () => {
      if (!authContext.authState.token) return null;
      return DefaultService.getCaregiverInfo(`Bearer ${authContext.authState.token}`, caregiver.params.caregiver.ID);
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  React.useEffect(() => {
    if (caregiver.params.caregiver.ID && caregiverData.data) {
      setFirstName(caregiverData.data.firstname);
      setName(caregiverData.data.name);
      setJob(caregiverData.data.jobId);
      setUsername(caregiverData.data.username);
      setDepartments(caregiverData.data.departments);
      setCurrentDepartment(caregiverData.data.currentDepartment);
      setId(caregiverData.data.ID);
    }
  }, [caregiverData.data]);

  const handleSubmit = async () => {
    const response = await DefaultService.updateCaregiver(`Bearer ${authContext.authState.token}`, id, {
      firstname: firstname,
      name: name,
      jobId: +job,
    });

    console.log(response);

    if (response.status === 'Updated') {
      displaySnackBar('Modifications enregistrées', 'SUCCESS');
    }
  };

  const deleteUserCaregiver = async () => {
    if(!currentDepartment) return displaySnackBar("Le doignant n'a pas été supprimé", 'ERROR');

     await DefaultService.deleteCaregiver(`Bearer ${authContext.authState.token}`, currentDepartment,caregiver.params.caregiver.ID);
      displaySnackBar('Soignant supprimé', 'SUCCESS');
      navigate(ROUTES.SERVICE_SCREEN.name);
  };

  return (
    <MainLayout serviceCard backButton white>
      <ModuleContentView>
        <Title h2 center>
          Mettre à jour un soignant
        </Title>
        <View style={styles.inputsContainer}>
          <InputText label={'Nom'} value={name} onChangeText={(value) => setName(value)} />
          <InputText label={'Prénom'} value={firstname} onChangeText={(value) => setFirstName(value)} />
          {/*<InputText label={'Identifiant'} value={username} onChangeText={(value) => setUsername(value)} />*/}
          <Title h3>Poste du soignant</Title>
          <RNPickerSelect
            onValueChange={(value) => setJob(value)}
            value={`${job}`}
            items={jobs}
            placeholder={{
              label: 'Poste',
            }}
            style={pickerSelectStyles}
          />
          <TouchableOpacity onPress={() => deleteUserCaregiver()}>
            <Title h3 secondary>
              Supprimer le soignant
            </Title>
          </TouchableOpacity>
          <Button label={'Mettre à jour'} large mvL onPress={() => handleSubmit()} />
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { UpdateCaregiverScreen };

const styles = StyleSheet.create({
  inputsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  servicesContainer: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 15,
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
  inputAndroid: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.8,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
});
