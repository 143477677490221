import React from 'react';
import { Button } from '../../components/buttons';
import { InputText } from '../../components/inputs/InputText';
import { Title } from '../../components/texts';
import MainLayout from '../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../layouts/dashboard/ModuleContentView';
import { useAuthContext } from '../../providers/AuthProvider';
import { useSnackBarContext } from '../../providers/SnackBarProvider';
import { IsContainNumber, IsContainSymbol, isContainUpper } from '../../utils/helpers/validations/passwordVerification';
import { useUserContext } from '../../providers/UserProvider';
import { PasswordValidationIndicator } from '../../components/feedbacks/PasswordValidationIndicator';
import { useNavigation } from '@react-navigation/native';
import { ROUTES } from '../../router/Routes';
import { DefaultService } from '../../generated/api';

const ChangePasswordScreen: React.FunctionComponent = () => {
  const userContext = useUserContext();
  const { navigate } = useNavigation();
  const authContext = useAuthContext();
  const { displaySnackBar } = useSnackBarContext();
  const [newPassword, setNewPassword] = React.useState('');
  const [passwordConf, setPasswordConf] = React.useState('');

  const updatePassword = async () => {
    if (
      newPassword.length < 8 ||
      !isContainUpper(newPassword) ||
      !IsContainNumber(newPassword) ||
      !IsContainSymbol(newPassword) ||
      newPassword !== passwordConf
    ) {
      displaySnackBar(`Vérifiez votre nouveau mot de passse`, 'ERROR');
      return;
    }

    const response = await DefaultService.updateCaregiver(
      `Bearer ${authContext.authState.token}`,
      authContext.authState.userID,
      {
        password: newPassword,
      }
    ).catch((e) => {
      console.log(e);
      displaySnackBar('Une erreur est survenue', 'ERROR');
    });
    console.log(response);
    if (response) {
      displaySnackBar('Mot de passe modifié avec succès', 'SUCCESS');
      navigate(ROUTES.HOME_SCREEN.name);
    } else {
      displaySnackBar('Une erreur est survenue', 'ERROR');
    }
  };

  return (
    <MainLayout serviceCard backButton white>
      <ModuleContentView>
        <Title h2 primary center>
          Modifier le mot de passe
        </Title>
        <Title h2 primary center mvXS>
          {userContext.userState?.profile?.user?.username}
        </Title>
        <InputText
          label="Nouveau Mot de passe"
          password
          value={newPassword}
          onChangeText={(value) => setNewPassword(value)}
          noSpace
        />
        <InputText
          label="Confirmer le mot de passe"
          value={passwordConf}
          password
          onChangeText={(value) => setPasswordConf(value)}
          onSubmitEditing={updatePassword}
          noSpace
        />
        <PasswordValidationIndicator password={newPassword} />
        <Button mvM label="Modifier le mot de passe" onPress={() => updatePassword()} />
      </ModuleContentView>
    </MainLayout>
  );
};
export { ChangePasswordScreen };
