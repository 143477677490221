import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { Title } from '../../../components/texts';
import RequestsBadge from '../../../components/badges/RequestsBadge';
import RequestCard from '../../../components/cards/RequestCard';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { useNavigation } from '@react-navigation/native';
import { ROUTES } from '../../../router/Routes';
import { Container } from '../../../layouts';
import getLastCategoryDataFromCategoriesPath from '../../../utils/helpers/getLastCategoryDataFromCategoriesPath';
import { getPriorityString } from '../../../utils/helpers/getPriority';
import { doestItHaveChildren } from '../../../utils/helpers/doestItHaveChildren';

interface IRequestsListsModule {
  fromLockScreen?: boolean;
  demands: any;
}

const RequestsListsModule = ({ fromLockScreen, demands }: IRequestsListsModule) => {
  const { navigate } = useNavigation();

  const renderItem = (request: any) => {
    let category;
    let parentCategory;
    if (doestItHaveChildren(request.category)) {
      const parsedCategories = getLastCategoryDataFromCategoriesPath(
        request.category,
        doestItHaveChildren(request.category)
      );
      category = parsedCategories.category;
      parentCategory = parsedCategories.parentCategory;
    } else {
      category = request.category;
    }

    if (category) {
      return (
        <TouchableOpacity
          onPress={() =>
            fromLockScreen
              ? navigate(ROUTES.PIN_SCREEN_WEB.name)
              : navigate(ROUTES.REQUEST_DETAILS_SCREEN.name, { id: request.ID })
          }
          key={request.ID}
          style={Platform.OS === 'web' ? styles.requestContainerWeb : null}
        >
          <RequestCard
            icon={category.icon ? category.icon : null}
            title={category.name ? category.name.FR : 'null'}
            date={request.createdAt}
            haveComment={category.comment}
            status={request.status.toUpperCase()}
            priority={getPriorityString(category.priority)}
            category={parentCategory ? `${parentCategory.name.FR} - ${category.name.FR}` : category.name.FR}
            id={category.id}
            position={request.position ? request.position : 'null'}
            fromLockScreen={fromLockScreen}
          />
        </TouchableOpacity>
      );
    }
  };

  if (!demands) return <></>;
  return (
    <Container>
      <View>
        <Container justifyBetween flexRow alignCenter>
          <Title h2>En attente de traitement</Title>
          <RequestsBadge
            count={
              demands.filter(
                (request: any) =>
                  request.status === StatusEnum.NEW ||
                  request.status === StatusEnum.BOOSTED ||
                  request.status === StatusEnum.REBOOSTED
              ).length
            }
          />
        </Container>

        <Container>
          {demands
            .filter(
              (request: any) =>
                request.status === StatusEnum.NEW ||
                request.status === StatusEnum.BOOSTED ||
                request.status === StatusEnum.REBOOSTED
            )
            .sort((a: any, b: any) => {
              return a.category.priority < b.category.priority ? -1 : 1;
            })
            .map((request: any) => renderItem(request))}
        </Container>
      </View>
      <Container>
        <Container justifyBetween flexRow alignCenter>
          <Title h2>En cours de traitement</Title>
          <RequestsBadge count={demands.filter((request: any) => request.status === StatusEnum.PROCESSING).length} />
        </Container>
        <Container>
          {demands
            .filter((request: any) => request.status === StatusEnum.PROCESSING)
            .map((request: any) => renderItem(request))}
        </Container>
      </Container>
    </Container>
  );
};

export default RequestsListsModule;

const styles = StyleSheet.create({
  requestContainerWeb: {
    width: 400,
    margin: 5,
  },
});
