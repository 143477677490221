import React from 'react';
import { FlatList, Image, Platform, TouchableOpacity, View } from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { useUserContext } from '../../../providers/UserProvider';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import HistoryCard from '../../../components/cards/HistoryCard';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import SelectDateHistory from '../../../components/date/SelectDateHistory';
import { Title } from '../../../components/texts';
import { ROUTES } from '../../../router/Routes';
import { Container } from '../../../layouts';
import { DefaultService } from '../../../generated/api';
import { useAuthContext } from '../../../providers/AuthProvider';
import { doestItHaveChildren } from '../../../utils/helpers/doestItHaveChildren';
import getLastCategoryDataFromCategoriesPath from '../../../utils/helpers/getLastCategoryDataFromCategoriesPath';

const ServiceHistoryScreen = () => {
  const authContext = useAuthContext();
  const [history, setHistory] = React.useState([]);
  const [activeDate, setActiveDate] = React.useState(new Date());
  const userContext = useUserContext();
  const { navigate } = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      if (!userContext.userState.profile) return;

      DefaultService.getHistoryDemand(
        `${activeDate.getFullYear()}`,
        `${activeDate.getMonth()}`,
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile.currentDepartment,
        `${activeDate.getDate()}`
      )
        .then((response) => {
          setHistory(
            response.records.map((request: any) => {
              let category;
              let parentCategory;
              if (doestItHaveChildren(request.demand.category)) {
                const parsedCategories = getLastCategoryDataFromCategoriesPath(
                  request.demand.category,
                  doestItHaveChildren(request.demand.category)
                );
                category = parsedCategories.category;
                parentCategory = parsedCategories.parentCategory;
              } else {
                category = request.demand.category;
              }

              return { demand: request.demand, category, parentCategory };
            })
          );
        })
        .catch((error) => {
          console.log('historyData', error);
        });
    }, [userContext.userState?.profile?.currentDepartment, activeDate])
  );

  const renderData = ({ item }: any) => {
    return (
      <TouchableOpacity onPress={() => navigate(ROUTES.REQUEST_HISTORY_DETAILS_SCREEN.name, { state:item })}>
        <HistoryCard history={item} />
      </TouchableOpacity>
    );
  };

  const NoActivityIndicator = () => {
    return (
      <Container flexCol alignCenter justifyCenter marginVertical={50}>
        <Image source={require('../../../assets/images/requests/noRequest.png')} style={{ width: 70, height: 70 }} />
        <Title h2 center>
          Aucune demande
        </Title>
        <Title h3 center>
          Aucune activité enregistrée sur Hospitalink ce jour-ci
        </Title>
      </Container>
    );
  };


  return (
    <MainLayout serviceCard backButton>
      <ModuleContentView>
        <Container>
          {Platform.OS !== 'web' && (
            <Title h2 black>
              Historique
            </Title>
          )}

          <SelectDateHistory date={activeDate} setActiveDate={setActiveDate} />
        </Container>
        <View>
          {history.length < 1 ? (
            <NoActivityIndicator />
          ) : (
            <FlatList data={history} renderItem={renderData} keyExtractor={(item: any) => item.id} />
          )}
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { ServiceHistoryScreen };
