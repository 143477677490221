import React from 'react';
import { initialUserState, userReducer } from './reducers/userReducer';
import userActions from './actions/userActions';
import graphAPI from '../api/graphAPI';
import { useAuthContext } from './AuthProvider';
import { useAppStateContext } from './AppstateProvider';
import { PasswordStatusEnum } from '../types/enums/PasswordStatusEnum';
import { Platform } from 'react-native';
import { DefaultService } from '../generated/api';
import { storePincodeToLocalStorages } from '../utils/helpers/localStorage';
import { useDepartmentContext } from './DepartmentProvider';

interface UserContextType {
  getPatientProfile: () => Promise<void>;
  userNeedToEnterCodePin: boolean;
  userState: any;
  changeCurrentDepartment: (departmentId: number) => Promise<void>;
  getServiceHistory: (date?: any, departmentId?: number) => Promise<any>;
  updateMyCaregiverProfile: (updateCaregiverInput: any) => Promise<any>;
  getCaregiverProfile: () => Promise<any>;
  getPatientStatus: (id:string) => Promise<any>;
  
}

const UserContext = React.createContext<UserContextType>({} as UserContextType);

const UserProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const authContext = useAuthContext();
  const appStateContext = useAppStateContext();
  const departmentContext = useDepartmentContext();
  const [userState, userDispatch] = React.useReducer(userReducer, initialUserState);
  const [userNeedToEnterCodePin, setUserNeedToEnterCodePin] = React.useState(false);


  //Vérifier si l'utilisateur doit chaner son code pin
  React.useEffect(() => {
    if (authContext.authState.isConnected && userState.profile?.passwordState === PasswordStatusEnum.NEED_CHANGE)
      return;
    setUserNeedToEnterCodePin(appStateContext.appStateVisible === 'active');
  }, [appStateContext.appStateVisible]);



  //Récupération des informations du soignant
  React.useEffect(() => {
    if (authContext.authState.isConnected && authContext.authState.userID) {
      getCaregiverProfile()
        .then((response) => {
        departmentContext.getServiceInfos(response.currentDepartment);

        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      userDispatch(userActions.resetUserState());
    }
  }, [authContext.authState.isConnected, authContext.authState.userID]);


  //Vérouillage automatique
  React.useEffect(() => {
    if (Platform.OS !== 'web') return;
    if (!authContext.authState.isConnected) return;
    const selfLockingDelay = setInterval(() => {
      appStateContext.setNeedToTapePinCode(true);
    }, 300000);

    return () => clearInterval(selfLockingDelay);
  }, [authContext.authState.isConnected]);



  /**
   * Récupération des informations du soignant
   */
  const getCaregiverProfile = async (): Promise<void> => {

      const user = await DefaultService.getCaregiverInfo(`Bearer ${authContext.authState.token}`, authContext.authState.userID).catch(error => {
          console.log({error})
      });
     
      if (user) {
        userDispatch(userActions.getProfile(user));
        await storePincodeToLocalStorages(user.pin);
        return user;
      }
    
  };


  const getPatientStatus = async (id:string) => {
    const response = await DefaultService.getPatientStatus(id, userState.profile.currentDepartment);
    if(response.status){
      return response.status;
    }
    return false;
  }

  /**
   * @param departmentId 
   * Changement du département actif
   */
  const changeCurrentDepartment = async (departmentId: number) => {
    const response = await graphAPI.user.changeDepartment(departmentId);
    if (response) {
      await getCaregiverProfile();
    }
  };

  /**
   * @param date 
   * @param departmentId 
   * @returns 
   */
  const getServiceHistory = async (date?: any, departmentId?: number) => {
    const id = departmentId ? departmentId : userState.profile.currentDepartment.id;
    return await graphAPI.user.getServiceHistory(date, id);
  };

  /**
   * @param updateCaregiverInput 
   * @returns 
   */
  const updateMyCaregiverProfile = async (updateCaregiverInput: any) => {
    const response = await graphAPI.user.updateMyCaregiverProfile(updateCaregiverInput).catch((e) => {
      console.log(e);
    });
    await getCaregiverProfile();
    return response;
  };


  return (
    <UserContext.Provider
      value={{
        getPatientProfile: getCaregiverProfile,
        userState,
        userNeedToEnterCodePin,
        changeCurrentDepartment,
        getServiceHistory,
        updateMyCaregiverProfile,
        getCaregiverProfile,
        getPatientStatus
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContextType => {
  return React.useContext(UserContext);
};

export {UserProvider, useUserContext};
