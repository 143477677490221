import React, { Context, createContext, useContext, useReducer } from 'react';
import { ISignIn, ISignUp, IUpdatePassword } from '../types/authTypes';
import { AuthStateType } from '../types/stateTypes';
import { Auth } from 'aws-amplify';
import asyncStorage from '@react-native-async-storage/async-storage';
import { DefaultService } from '../generated/api';

interface AuthContextElements {
  authState: AuthStateType;
  login: (loginPayload: ISignIn) => any;
  signup: (signupPayload: ISignUp) => any;
  loginWithTokens: () => any;
  logout: () => Promise<void>;
  changePassword: (changePasswordPayload: IUpdatePassword) => Promise<any>;
  checkTokenValidity: any;
  setNewPassword: any;
  setNewCaregiverCredentials: any;
  setNewPinCode: any;
  setAuthState: any;
}

const AuthContext: Context<AuthContextElements> = createContext({} as AuthContextElements);

const AuthProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  //const [authState, authDispatch]: any = useReducer(authReducer as any, initialAuthState);
  const [authState, setAuthState] = React.useState({
    isConnected: false,
    isLoading: false,
    isInitialized: false,
    error: false,
    token: '',
    refreshToken: '',
    tokenInfos: '',
    oldPassword: '',
    newPassword: '',
    oldPinCode: '',
    newPinCode: '',
    userID: '',
    displayErrorJson: '',
  });

  const login = async (signInPayload: ISignIn) => {
    try {
      const user = await Auth.signIn(signInPayload);
      if (user && user.attributes) {
        const idToken = user.signInUserSession.idToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.token;

        setAuthState({
          ...authState,
          isConnected: true,
          isLoading: false,
          isInitialized: true,
          token: idToken,
          refreshToken: refreshToken,
          userID: user.attributes.sub,
        });

        await asyncStorage.setItem('token', idToken);
        await asyncStorage.setItem('refreshToken', refreshToken);
        await asyncStorage.setItem('userID', user.attributes.sub);

        return user;
      } else {
        throw new Error('Connexion Impossible');
      }
    } catch (error) {

      console.log('error signing in', error);
      setAuthState({
        ...authState,
        displayErrorJson: JSON.stringify(error),
      });
      throw new Error('Connexion Impossible');
    }
  };

  const signup = (signupPayload: ISignUp) => {};

  const logout = async () => {
    try {
      await asyncStorage.removeItem('token');
      await asyncStorage.removeItem('refreshToken');
      await asyncStorage.removeItem('userID');
      await Auth.signOut({ global: true });
      setAuthState({
        isConnected: false,
        isLoading: false,
        isInitialized: false,
        error: false,
        token: '',
        refreshToken: '',
        tokenInfos: '',
        oldPassword: '',
        newPassword: '',
        oldPinCode: '',
        newPinCode: '',
        userID: '',
        displayErrorJson: '',
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const checkTokenValidity = () => {};

  const refreshToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        async (err: any, session: { idToken: { jwtToken: any }; refreshToken: { token: any } }) => {
          const idToken = session.idToken.jwtToken;
          const refreshToken = session.refreshToken.token;
          await asyncStorage.setItem('token', idToken);
          await asyncStorage.setItem('refreshToken', refreshToken);
          return true;
        }
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  };

  const loginWithTokens = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        async (err: any, session: { idToken: { jwtToken: any }; refreshToken: { token: any } }) => {
          if (err) return;

          const idToken = session.idToken.jwtToken;
          const refreshToken = session.refreshToken.token;

          setAuthState({
            ...authState,
            isConnected: true,
            isLoading: false,
            isInitialized: true,
            token: idToken,
            refreshToken: refreshToken,
            userID: session.idToken.payload.sub,
          });
          await asyncStorage.setItem('token', idToken);
          await asyncStorage.setItem('refreshToken', refreshToken);
          return true;
        }
      );
    } catch (e) {
      console.log('Unable to login with tokens', e);
    }
  };

  const setNewCaregiverCredentials = async (pincode: string): Promise<any> => {
    try {
      const passwordResponse = await DefaultService.resetPassword(`Bearer ${authState.token}`, authState.userID, {
        password: authState.newPassword,
      });
      const pincodeResponse = await DefaultService.resetPin(`Bearer ${authState.token}`, authState.userID, {
        pin: pincode,
      });
      if (passwordResponse && pincodeResponse) {
        return true;
      }
    } catch (e) {
      console.log('Unable to set new caregiver credentials', e);
      return false;
    }
  };

  const setNewPassword = async (newPasswordPayload: any): Promise<any> => {
    setAuthState({
      ...authState,
      oldPassword: newPasswordPayload.oldPassword,
      newPassword: newPasswordPayload.newPassword,
    });
  };

  const setNewPinCode = async (newPinCodePayload: any): Promise<any> => {
    setAuthState({
      ...authState,
      oldPinCode: newPinCodePayload.oldPinCode,
      newPinCode: newPinCodePayload.newPinCode,
    });
  };
  const changePassword = async (changePasswordPayload: IUpdatePassword) => {};

  React.useEffect(() => {
    if (!authState.isConnected) return;

    const refreshTokenInterval = setInterval(async () => {
      await refreshToken();
    }, 600000);

    return () => clearInterval(refreshTokenInterval);
  }, [authState.isConnected]);

  return (
    <AuthContext.Provider
      value={{
        changePassword,
        authState,
        login,
        signup,
        logout,
        loginWithTokens,
        checkTokenValidity,
        setNewPassword,
        setAuthState,
        setNewCaregiverCredentials,
        setNewPinCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = (): AuthContextElements => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
