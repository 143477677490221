import React from 'react';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Title } from '../../../components/texts';
import { StyleSheet } from 'react-native';
import { InputText } from '../../../components/inputs/InputText';
import { Button } from '../../../components/buttons';
import RNPickerSelect from 'react-native-picker-select';
import { useCaregiversContext } from '../../../providers/CaregiversProvider';
import { useAuthContext } from '../../../providers/AuthProvider';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { useNavigation } from '@react-navigation/native';
import { ROUTES } from '../../../router/Routes';
import { Container } from '../../../layouts';
import { useUserContext } from '../../../providers/UserProvider';
import { DefaultService } from '../../../generated/api';

const AddCaregiverScreen = () => {
  const authContext = useAuthContext();
  const { displaySnackBar } = useSnackBarContext();
  const userContext = useUserContext();
  const { navigate } = useNavigation();

  const [loading, setLoading] = React.useState(false);
  const [jobs, setJobs] = React.useState([
    { label: 'IDE', value: "1" },
    { label: 'AF', value: "2" },
    { label: 'SF', value: "3" },
    { label: 'CADRE', value: "5" },
  ]);
  const [name, setName] = React.useState('');
  const [firstname, setFirstName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [job, setJob] = React.useState<number>(0);


  const handleSubmit = async () => {
    setLoading(true);
    try {
      console.log({
        firstname,
        pinCode: '0000',
        name,
        departmentIds: [+userContext.userState.profile.currentDepartment],
        username: username.trim(),
        jobId: job,
      })
      const response = await DefaultService.createCaregiver(
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile.currentDepartment,
        {
          firstname,
          pinCode: '0000',
          name,
          departmentIds: [+userContext.userState.profile.currentDepartment],
          username: username.trim(),
          jobId: +job,
        }
      );

      if (response) {
        displaySnackBar('Soignant créé avec succès', 'SUCCESS');
        setLoading(false);
        navigate(ROUTES.SERVICE_SCREEN.name);
      }
    } catch (e) {
      console.log(e);
      displaySnackBar('Ce compte ne peut pas être créé', 'ERROR');
    }

    setLoading(false);
  };

  return (
    <MainLayout serviceCard backButton>
      <ModuleContentView>
        <Title h2>Ajouter un soignant</Title>
        <Container flexCol alignCenter justifyCenter>
          <InputText label={'Nom'} value={name} onChangeText={(value) => setName(value)} />
          <InputText label={'Prénom'} value={firstname} onChangeText={(value) => setFirstName(value)} />
          <InputText label={'Identifiant'} value={username} onChangeText={(value) => setUsername(value.trim())} />
          <RNPickerSelect
            onValueChange={(value) => setJob(value)}
            items={jobs}
            placeholder={{
              label: 'Poste',
            }}
            style={pickerSelectStyles}
          />
          <Button label={'Ajouter un soignant'} large mvL onPress={handleSubmit} loading={loading} />
        </Container>
      </ModuleContentView>
    </MainLayout>
  );
};

export { AddCaregiverScreen };

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
  inputAndroid: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    flex: 1,
    borderColor: 'gray',
    borderRadius: 10,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    shadowColor: '#9d9d9d',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.2,
    shadowRadius: 11.95,
    elevation: 18,
    backgroundColor: 'white',
    height: 65,
    marginVertical: 15,
  },
});
