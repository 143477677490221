import React from 'react';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { StyleSheet, View } from 'react-native';
import { Title } from '../../../components/texts';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Colors } from '../../../styles/theme';
import { InputText } from '../../../components/inputs/InputText';
import { Button } from '../../../components/buttons';
import { useUserContext } from '../../../providers/UserProvider';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { useNavigation } from '@react-navigation/native';
import { ROUTES } from '../../../router/Routes';
import { DefaultService } from '../../../generated/api';
import { useAuthContext } from '../../../providers/AuthProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';

const UpdateMyCodeScreen = () => {
  const userContext = useUserContext();
  const authContext = useAuthContext();
  const { navigate } = useNavigation();
  const { displaySnackBar } = useSnackBarContext();
  const [pin, setPin] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmitPin = async () => {
    setLoading(true);
    if(pin.length !== 4) {
      displaySnackBar('Le code pin doit contenir 4 chiffres', 'ERROR');
      setLoading(false);
      return;
    }

    const response = await DefaultService.updateCaregiver(
      `Bearer ${authContext.authState.token}`,
      authContext.authState.userID,
      {
        pinCode: pin,
      }
    ).catch((e) => {
      console.log(e);
      displaySnackBar('Une erreur est survenue', 'ERROR');
    });
    if (response) {
      displaySnackBar('Modification enregistrée !', 'SUCCESS');
      authContext.setAuthState({ ...authContext.authState, pinCode: pin });
      await AsyncStorage.setItem('pincode', pin);
      navigate(ROUTES.HOME_SCREEN.name);
    } else {
      displaySnackBar('Une erreur est survenue', 'ERROR');
    }
    setLoading(false);
  };

  const handleSetPin = (value: string) => {
    if (value.toString().length > 4) return;
    setPin(value.replace(/\D/g, '').replace(/[^0-9]/g, ''));
  };


  return (
    <MainLayout backButton serviceCard>
      <ModuleContentView>
        <View>
          <Title h2>Informations</Title>
          <View style={styles.detailsContainer}>
            <Title h3>Changer de code pin</Title>
            <InputText label={'Code pin'} value={pin} onChangeText={(value) => handleSetPin(value)} numeric noSpace />
            <Button label={'Valider'} success onPress={handleSubmitPin} loading={loading} />
          </View>
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export default UpdateMyCodeScreen;

const styles = StyleSheet.create({
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    padding: 20,
    marginVertical: 10,
  },
});
