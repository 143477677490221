//TODO: add tests

interface Category {
  categoryType: string;
  children: Array<Category>;
  emergency: boolean;
  going_coming: boolean;
  icon: string;
  id: string;
  name: { FR: string; EN: string };
  order: number;
  slug: string;
  tags: Array<string>;
}

let parentCategory: Category | null = null;
const getLastCategoryDataFromCategoriesPath: any = (categoriesPath: Category, doestItHaveChildren: boolean) => {
  if (!doestItHaveChildren) return { parentCategory, category: categoriesPath };

  if (categoriesPath && categoriesPath.hasOwnProperty('children') && categoriesPath.children.length > 0) {
    parentCategory = categoriesPath;
    return getLastCategoryDataFromCategoriesPath(categoriesPath.children[0]);
  }
  return { parentCategory, category: categoriesPath };
};

export default getLastCategoryDataFromCategoriesPath;
