import { DefaultService } from '../generated/api/services/DefaultService';
import React from 'react';
import { featuresReducer, initialFeaturesState } from './reducers/featuresReducer';
import { FeaturesService } from '../services/FeaturesFleeping.service';
import featuresActions from './actions/featuresAction';

interface DepartmentContextType {
  getServiceInfos: (id: string) => Promise<void>;
  departmentInfos: any;
}

const DepartmentContext = React.createContext<DepartmentContextType>({} as DepartmentContextType);

const DepartmentProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [departmentInfos, setDepartmentInfos] = React.useState<any>(null);
  

  const getServiceInfos = async (id:string) => {
    try{
      const serviceInfos = await DefaultService.getServiceInfos(id);
      if (serviceInfos) {
        setDepartmentInfos(serviceInfos);
      }
    }
    catch(err){
      console.log(err)
    }
  };

  const setFeaturesEnabled = async (features: Array<any>) => {
    const newFeaturesState = {
      communication: false,
      hospiteam: false,
      information: false,
      isInitialized: true,
    };

    features.map((feature) => {
      // @ts-ignore
      newFeaturesState[`${feature.feature}`] = true;
    });

  };

  return (
    <DepartmentContext.Provider
    value={{getServiceInfos, departmentInfos}}
    >
      {children}
    </DepartmentContext.Provider>
  );
};

const useDepartmentContext = (): DepartmentContextType => {
  return React.useContext(DepartmentContext);
};

export { DepartmentProvider, useDepartmentContext };
